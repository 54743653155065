import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Add, TableChart, TableRows } from '@mui/icons-material';
import './ProjectsPage.css';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import Project from '@src/Objects/Project';
import { textContainsLowerCase } from '@src/Objects/Helper';
import ProjectDialog from '@src/Components/Dialogs/ProjectDialog/ProjectDialog';
import ProjectOverlay from '@src/Components/Overlays/ProjectOverlay/ProjectOverlay';
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import getProjects from '@src/APIs/graphQl/Project/getProjects';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import ProjectCard from '@src/App/NewLayout/Cards/Projects/ProjectCard/ProjectCard';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';


/**
 * Represents the ProjectsPage component.
 *
 * @returns {JSX.Element} The rendered ProjectsPage component.
 */
function ProjectsPage()
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).projects;
    const permissions = useSelector((state: AppState) => state.permissions);
    const entities = useSelector((state: AppState) => state.projects)

    const emp = useSelector((state: AppState) => state.employee);
    const [selected, setSelected] = useState<number[]>([]);
    const isDense = (width: number) => width <= 1600
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    useEffect(() =>
    {
        getProjects().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);

    const types: IViewType<Project>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 280,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.PROJECT}
                        dense={isDense(width)}
                        noCheckbox={true}
                        selected={selected}
                        setSelected={setSelected}
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.projectName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.SALES_PROJECTS}/`,
                        },
                        {
                            id: 'business',
                            label: langStrings.customer,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                            minWidth: 530,
                        },
                        {
                            id: 'revenue',
                            label: langStrings.revenue,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 380,
                            type: 'currency',
                        },
                        {
                            id: 'dayPrice',
                            label: langStrings.dayPrice,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 800,
                            type: 'currency',
                        },
                        {
                            id: 'percent',
                            label: langStrings.hours,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 310,
                            type: 'percent',
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1550,
                            type: 'description',
                        },
                        {
                            id: 'employees',
                            label: langStrings.employees,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 900,
                            type: 'string',
                        },
                        {
                            id: 'isHours',
                            label: langStrings.isHours,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 1000,
                            type: 'hours',
                        },
                        {
                            id: 'totalHours',
                            label: langStrings.planHours,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 1050,
                            type: 'hours',
                        },
                        {
                            id: 'active',
                            label: langStrings.active,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1100,
                            type: 'string',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <ProjectOverlay
                                        projectId={entity.id}
                                    >
                                        {/* <NavLink to={entity.link}> */}
                                        {entity.title}
                                        {/* </NavLink> */}
                                    </ProjectOverlay>,
                                    orderKey: entity.title
                                },
                                business: entity.business.title,
                                revenue: entity.revenue,
                                dayPrice: entity.dayPrice,
                                percent: entity.percent,
                                description: entity.description,
                                employees: entity.employees.length,
                                isHours: entity.isHours,
                                totalHours: entity.totalHours,
                                active: `${entity.active ? langStrings.yes : langStrings.no}`,
                            }
                            ))
                        } />

            ,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <ProjectCard
                            key={entity.id}
                            project={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'create',
            text: langStrings.newProject,
            icon: <Add />,
            filter()
            {
                if (!checkPermissions('Projects', 'Add', permissions))
                    return false;
                return true;

            },
            action()
            {
                return openDialog(
                    <ProjectDialog
                        id="ProjectDialog"
                    />);
            },
        }
    ];
    return (
        <EntitiesPage
            title={langStrings.projects}
            entities={entities}
            selected={selected}
            loadingError={loadingError}

            setSelected={setSelected}
            views={[
                {
                    id: 'my', title: langStrings.oftenUsed.myEntries,
                    filter(entity)
                    {
                        return entity.employees.find(e => e.id === emp?.id) !== undefined
                    },
                },
                {
                    id: 'active', title: 'Aktive Einträge',
                    filter(entity)
                    {
                        return entity.active === true;
                    },
                },
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },

                {
                    id: 'missingPrice', title: langStrings.missingPrice,
                    filter(entity)
                    {
                        return !entity.dayPrice || entity.dayPrice === 0;
                    },
                },
            ]}
            types={types}
            actions={actions}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) &&
                (
                    textContainsLowerCase(searchText, entity.title)
                    || textContainsLowerCase(searchText, entity.business.title)
                    || textContainsLowerCase(searchText, entity.id.toString())
                    || textContainsLowerCase(searchText, entity.tenantId.toString())
                )
            }
        />
    );
}

export default ProjectsPage;