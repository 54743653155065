import { DirectionsRun, PriorityHigh, Sync } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { ETimeSyncStatus, ETimeSyncStartType, ETimeSyncOption, ICompanyTimeSyncStatis } from '@sharedInterfaces/ICompanySettings';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import getCompanySettings from '@src/APIs/graphQl/Company/getCompanySettings';
import startTimeSync from '@src/APIs/graphQl/Company/startTimeSync';
import { AppState } from '@store/store';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

export default function SyncTimeButton()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const permissions = useSelector((state: AppState) => state.permissions);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const companyId = useSelector((state: AppState) => state.company.companyId);

    const [syncStats, setSyncStats] = useState<ICompanyTimeSyncStatis | null>(null);

    const setStatus = (status: ETimeSyncStatus) => syncStats && setSyncStats({ ...syncStats, status: status });
    const loadData = () => getCompanySettings(companyId).then(company =>
    {

        if (company.companySettings.timeSync === ETimeSyncOption.NONE) return;
        switch (company.companySettings.timeSync)
        {
            case ETimeSyncOption.CLOCKODO:
                return setSyncStats(company.companySettings.clockodoStatis);
            case ETimeSyncOption.ZEP:
                return setSyncStats(company.companySettings.zepStatis);
        }
    });
    useEffect(() =>
    {
        if (!permissions.admin) return;
        loadData();
        const timeout = setInterval(() =>
        {
            if (syncStats?.status !== ETimeSyncStatus.SYNCING)
            {
                clearInterval(timeout);
                return;
            }

            loadData();
        }, 15000);
        return () => clearInterval(timeout);
    },
        [syncStats?.status]);


    if (!permissions.admin) return <div></div>
    const onClick = function (): void
    {
        startTimeSync(ETimeSyncStartType.NORMAL)
            .then(() => setStatus(ETimeSyncStatus.SYNCING))
            .catch(() => setStatus(ETimeSyncStatus.ERROR))
            .finally(() =>
            {
                setTimeout(loadData, 2000);
            });
    };
    const icon = syncStats?.status === ETimeSyncStatus.SYNCING
        ? <DirectionsRun /> :
        syncStats?.status === ETimeSyncStatus.ERROR
            ? <PriorityHigh />
            : <Sync />;

    return <div>
        <MenuItem
            onClick={onClick}
            disabled={!syncStats || syncStats.status === ETimeSyncStatus.SYNCING || syncStats.status === ETimeSyncStatus.ERROR}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText>
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                    {langStrings.syncTimes}
                    <Typography variant='caption'>
                        {langStrings.lastUpdate}:
                    </Typography>
                    <Typography variant='caption'>
                        {
                            !syncStats?.lastUpdateEmployeeTimesThisYear
                                ? '-'
                                : new Date(syncStats.lastUpdateEmployeeTimesThisYear).toLocaleString()
                        }
                    </Typography>
                </div>
            </ListItemText>
        </MenuItem>
    </div>
}