
import { EEntityType } from "src/sharedInterfaces/globalEnums";

import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed"

export type IEntityTypesTexts = {
    [key in EEntityType]: string;
};

type ILangData = {
    [lang in ELanguage]: IEntityTypesTexts
}
export const entityTypesTexts: ILangData = {
    'th': {
        empmonthproject: 'ส่งออกเวลาของพนักงานในโครงการ',
        availmonth: 'เดือนที่พร้อมใช้งาน',
        absence: 'การขาดงาน',
        empsettings: 'การตั้งค่าพนักงาน',
        role: oftenUsedTranslations['th'].role,
        smallRole: oftenUsedTranslations['th'].role,
        skillcategory: oftenUsedTranslations['th'].skillCategory,
        product: oftenUsedTranslations['th'].product,
        employee: oftenUsedTranslations['th'].employee,
        skill: oftenUsedTranslations['th'].skill,
        certificate: oftenUsedTranslations['th'].certificate,
        project: oftenUsedTranslations['th'].project,
        business: oftenUsedTranslations['th'].business,
        opportunity: oftenUsedTranslations['th'].opportunity,
        competence: oftenUsedTranslations['th'].competence,
        smallSkill: oftenUsedTranslations['th'].skill,
        smallCertificate: oftenUsedTranslations['th'].certificate,
        smallCompetence: oftenUsedTranslations['th'].competence,
        smallEmployee: oftenUsedTranslations['th'].employee,
        orgunit: oftenUsedTranslations['th'].orgUnit,
        attachment: oftenUsedTranslations['th'].attachment,
        notification: oftenUsedTranslations['th'].notification,
        adminNotification: oftenUsedTranslations['th'].adminNotification,
    },
    'en': {
        availmonth: 'Availability Month',
        absence: 'Absence',
        empsettings: 'Employee Settings',
        empmonthproject: 'Employee Project Time Export',
        role: oftenUsedTranslations['en'].role,
        smallRole: oftenUsedTranslations['en'].role,
        skillcategory: oftenUsedTranslations['en'].skillCategory,
        product: oftenUsedTranslations['en'].product,
        employee: oftenUsedTranslations['en'].employee,
        skill: oftenUsedTranslations['en'].skill,
        certificate: oftenUsedTranslations['en'].certificate,
        project: oftenUsedTranslations['en'].project,
        business: oftenUsedTranslations['en'].business,
        opportunity: oftenUsedTranslations['en'].opportunity,
        competence: oftenUsedTranslations['en'].competence,
        smallSkill: oftenUsedTranslations['en'].skill,
        smallCertificate: oftenUsedTranslations['en'].certificate,
        smallCompetence: oftenUsedTranslations['en'].competence,
        smallEmployee: oftenUsedTranslations['en'].employee,
        orgunit: oftenUsedTranslations['en'].orgUnit,
        attachment: oftenUsedTranslations['en'].attachment,
        notification: oftenUsedTranslations['en'].notification,
        adminNotification: oftenUsedTranslations['en'].adminNotification,
    },
    'de': {
        availmonth: 'Verfügbarkeitsmonat',
        absence: 'Abwesenheit',
        empsettings: 'Mitarbeiter Einstellungen',
        empmonthproject: 'Mitarbeiter Projekt-Zeiten-Export',
        skillcategory: oftenUsedTranslations['de'].skillCategory,
        product: oftenUsedTranslations['de'].product,
        employee: oftenUsedTranslations['de'].employee,
        skill: oftenUsedTranslations['de'].skill,
        certificate: oftenUsedTranslations['de'].certificate,
        project: oftenUsedTranslations['de'].project,
        business: oftenUsedTranslations['de'].business,
        opportunity: oftenUsedTranslations['de'].opportunity,
        competence: oftenUsedTranslations['de'].competence,
        role: oftenUsedTranslations['de'].role,
        smallSkill: oftenUsedTranslations['de'].skill,
        smallCertificate: oftenUsedTranslations['de'].certificate,
        smallCompetence: oftenUsedTranslations['de'].competence,
        smallEmployee: oftenUsedTranslations['de'].employee,
        smallRole: oftenUsedTranslations['de'].role,
        orgunit: oftenUsedTranslations['de'].orgUnit,
        attachment: oftenUsedTranslations['de'].attachment,
        notification: oftenUsedTranslations['de'].notification,
        adminNotification: oftenUsedTranslations['de'].adminNotification,
    },
}