import { stageContext } from "src/globals";

import { ELanguage, getLanguageTexts } from "./Language/languageHelper";

export enum EWebsocketTopics
{
    TEST = 'test',
    analyseCvText = 'analyseCvText',
    analyseOpportunity = "analyseOpportunity",
    generateSkillMetadata = "generateSkillMetadata",
    generateCertificateMetadata = "generateCertificateMetadata",
    generateRoleMetadata = "generateRoleMetadata",
    generateCompetenceMetadata = "generateCompetenceMetadata",
    suggestCategorizeSkills = "suggestCategorizeSkills",
    suggestCategorizationForSkillsWebsocket = "suggestCategorizationForSkillsWebsocket",
    refreshToken = "refreshToken",
    INIT = "INIT",
    attachmentUpload = "attachmentUpload",
    NOTIFICATION = "notification",
    SUGGESTIONS = "suggestions",
    GENERAL = "GENERAL",
}

export enum EEntityType
{
    ABSENCE = 'absence',
    AVAILABILITYMONTH = 'availmonth',
    BUSINESS = "business",
    CERTIFICATE = 'certificate',
    COMPETENCE = "competence",
    EMPLOYEE = 'employee',
    EMPLOYEE_MONTH_PROJECT_DATA = "empmonthproject",
    EMPLOYEESETTINGS = "empsettings",
    OPPORTUNITY = "opportunity",
    ORGANISATIONAL_UNIT = 'orgunit',
    PRODUCT = 'product',
    PROJECT = 'project',
    ROLE = "role",
    SKILL = 'skill',
    SKILL_CATEGORY = 'skillcategory',
    SMALL_CERTIFICATE = "smallCertificate",
    SMALL_COMPETENCE = "smallCompetence",
    SMALL_EMPLOYEE = "smallEmployee",
    SMALL_ROLE = "smallRole",
    SMALL_SKILL = "smallSkill",
    ATTACHMENT = "attachment",
    ADMIN_NOTIFICATION = "adminNotification",
    NOTIFICATION = "notification",
}

export enum EMailCycle
{
    NEVER = 'never',
    MONTLY = 'monthly',
}

//Falls jemals ein Update gemacht wird auch im SkillMetaDataGenerator berücksichtigen
export enum ESkillType
{
    HARD_SKILL = 'Hard-Skill',
    SOFT_SKILL = 'Soft-Skill',
    LANGUAGE_SKILL = 'Sprach-Skill',
}

/**
 * Converts an entity type to string representation.
 * 
 * @param {ELanguage} lang - The language to use for the conversion.
 * @param {EEntityType} type - The entity type to convert.
 * @returns {string} The converted entity type as a string.
 */
export function entityTypeToString(lang: ELanguage, type: EEntityType): string
{
    const langStrings = getLanguageTexts(lang).entityTypes;
    return langStrings[type];
}

export enum ELinks
{
    LOGIN = "login",
    LOGGEDIN = "loggedin",
    LOGGEDOUT = 'loggedout',
    CERTIFICATES = 'certificates',
    COMPETENCE = "competence",
    PEOPLE = 'people-org',
    PEOPLE_EMPLOYEES = 'people-org/employee',
    PEOPLE_ORGUNITS = 'people-org/orgunits',
    PROFILE = 'profile',
    PROFILE_EDIT = 'profile/edit',
    PROFILE_EDIT_ANALYSE = 'profile/edit/analyse',
    PROFILE_EDIT_ROLES = "profile/edit/roles",
    PROFILE_EDIT_CERTIFICATES = 'profile/edit/certificates',
    PROFILE_EDIT_DEVELOPMENT = 'profile/edit/development',
    PROFILE_EDIT_FORECAST = 'profile/edit/forecast',
    PROFILE_EDIT_SKILLS = 'profile/edit/skills',
    PROFILE_EDIT_SKILLS_SUGGESTIONS = 'profile/edit/skills/suggestions',
    PROFILE_SHOW = 'profile/show',
    SALES = 'sales',
    SALES_PRODUCTS = 'sales/products',
    SALES_CUSTOMERS = 'sales/customers',
    SALES_OPPORTUNITIES = 'sales/opportunities',
    SALES_PROJECTS = 'sales/projects',
    SETTINGS = "settings",
    SETTINGS_CALCULATIONS = "settings/calculations",
    SETTINGS_EMAIL = "settings/mails",
    SETTINGS_TIME_SYNC = "settings/timesync",
    SETTINGS_HOLIDAYS = "settings/holidays",
    SETTINGS_LEVELS = "settings/levels",
    SETTINGS_ORGUNITS = "settings/orgunits",
    SETTINGS_PERMISSIONS = "settings/permissions",
    SETTINGS_REGISTRATION = "settings/registration",
    SETTINGS_STYLE = "settings/style",
    SETTINGS_RESTAPI = "settings/restapi",
    SETTINGS_MICROSOFT = "settings/microsoft",
    SETTINGS_SUGGESTIONS = "settings/suggestions",
    SKILLCATEGORIES = 'skillcategories',
    SKILLS = 'skills',
    ROLES = "roles",
    DOCUMENTATION = "documentation",
    DOCUMENTATION_REST = "documentation/restapi",
    EMAIL_UNSUBSCRIBE = "unsubscribe",
    EMAIL_SETTINGS = "email-settings",


    FOOTER_IMPRINT = 'https://competence.guru/imprint',
    FOOTER_CONTACT = 'https://frye.tech/#contact',
    BUGREPORT = "https://tasks.office.com/noventum.onmicrosoft.com/Home/PlanViews/gQ-ErQ2o-0-PLnXvTRi91JYAFRaJ?Type=PlanLink&Channel=Link&CreatedTime=638327150405840000",

}


/**
 * Converts the entity type and id to a link.
 * 
 * @param {number} id - The id of the entity.
 * @param {EEntityType} type - The type of the entity.
 * @returns {string} The link generated from the entity type and id.
 */
export function entityTypeToLink(id: number | string, type: EEntityType, tab?: string): string
{
    let link = '';
    switch (type)
    {
        case EEntityType.SKILL_CATEGORY:
            link = `/${ELinks.SKILLCATEGORIES}/${id}`;
            break;
        case EEntityType.PRODUCT:
            link = `/${ELinks.SALES_PRODUCTS}/${id}`;
            break;
        case EEntityType.AVAILABILITYMONTH:
            link = `/${ELinks.PROFILE_EDIT_FORECAST}`;
            break;
        case EEntityType.EMPLOYEE:
            link = `/${ELinks.PEOPLE_EMPLOYEES}/${id}`;
            break;
        case EEntityType.SKILL:
            link = `/${ELinks.SKILLS}/${id}`;
            break;
        case EEntityType.CERTIFICATE:
            link = `/${ELinks.CERTIFICATES}/${id}`;
            break;
        case EEntityType.PROJECT:
            link = `/${ELinks.SALES_PROJECTS}/${id}`;
            break;
        case EEntityType.BUSINESS:
            link = `/${ELinks.SALES_CUSTOMERS}/${id}`;
            break;
        case EEntityType.COMPETENCE:
            link = `/${ELinks.COMPETENCE}/${id}`;
            break;
        case EEntityType.OPPORTUNITY:
            link = `/${ELinks.SALES_OPPORTUNITIES}/${id}`;
            break;
        case EEntityType.ROLE:
            link = `/${ELinks.ROLES}/${id}`;
            break;
        case EEntityType.ORGANISATIONAL_UNIT:
            link = `/${ELinks.PEOPLE_ORGUNITS}/${id}`;
            break;
        default:
            return '/unknown-entity';
    }
    if (!tab) return link;
    return link + `/${tab}`;
}

/**
 * Checks if the given argument is of type ESkillType.
 *
 * @param {ESkillType} arg - The argument to check.
 * @return {boolean} - Returns true if the argument is of type ESkillType, otherwise false.
 */
export function isESkillType(arg: ESkillType): arg is ESkillType
{
    return Object.values(ESkillType).includes(arg);
}

/** 
 * Sensibilität für EmployeeSugestionBerechnung
*/
export const sensitivityEmployeeSuggestionCalculation = {
    missingSkill: -0.1,
    lowLevel: -0.05,
    highLevel: 0.01
};

export enum EEmployeePictureSize
{
    XS = 38,
    S = 100,
    M = 300,
    L = 500,
    XL = 1000,
}

export function generateEmployeePictureUrl(companyId: string, employee: { id: number, pictureId: string | null }, size: EEmployeePictureSize)
{
    return `https://${(stageContext as { domain?: string }).domain}/static/profilepictures/${companyId}/${employee.id}-${size}-${employee.pictureId}.png`;
}