import React from 'react';
import { Menu, Settings, } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import UserButton from '@src/Components/UserButton/UserButton';
import './Header.css';
import SearchBox from '@src/Components/SearchBox/SearchBox';
import { IconButton, Tooltip, } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { useNavigate } from 'react-router-dom';
import { stageContext } from '@src/globals';

import { NotificationButton } from './NotificationButton';

const MemoizedSearchBox = React.memo(SearchBox);
const MemoizedUserButton = React.memo(UserButton);

interface HeaderProps
{
  toggleNav: () => void;
  navIsOpen: boolean;
}

function Header({ toggleNav, navIsOpen }: HeaderProps)
{
  const companyName = useSelector((state: AppState) => state.company.name);
  const width = useSelector((state: AppState) => state.windowSize.width);
  const permissions = useSelector((state: AppState) => state.permissions);
  const logoId = useSelector((state: AppState) => state.company.style.logoId);
  const companyId = useSelector((state: AppState) => state.company.companyId);

  // const title = React.useMemo(
  //   () => `CompetenceManager ${companyName && width > 800 ? ' - ' + companyName : ''}`,
  //   [companyName, width]
  // );

  return (
    <header className="header">
      <div className='left-part'>
        {companyName && (
          <div className={"navButton" + (navIsOpen ? ' navIsOpen' : '')} onClick={toggleNav}>
            <Menu />
          </div>
        )}

        <div className="productTitle">
          <div className='protos-o' style={{
            backgroundImage: `url('/protos-o.png')`
          }} />
          {width > 600
            ? stageContext.productName
            : ''
          }
        </div>
      </div>
      <div className='companyTitle'>
        {/* {companyName} */}
        {logoId ?
          <img src={`https://${stageContext.domain}/static/logo/${companyId}/50-${logoId}.png`} className='company-logo' alt={`Logo ${companyName}`} />
          :
          companyName
        }
      </div>
      <div className='rightOptions'>
        {companyName && <MemoizedSearchBox />}
        <NotificationButton />
        {permissions.admin && <SettingsButton />}
        <MemoizedUserButton />
      </div>
    </header>
  );
}

export default Header;

function SettingsButton()
{
  const navigate = useNavigate();
  const lang = useSelector((state: AppState) => state.employee.language);
  const langStrings = getLanguageTexts(lang).navigation;

  return (
    <Tooltip title={langStrings.settings} placement='bottom-start'>
      <IconButton
        onClick={() =>
        {
          navigate(`/${ELinks.SETTINGS}`);
        }}
      >
        <Settings style={{
          color: 'var(--var-on-secondary-color)'
        }} />
      </IconButton>
    </Tooltip>
  );
}
