import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IOrgUnitTexts extends ITextBase
{
    trendBorderTitle: string;
    sensitivityBorder: string;
    showEmployees: string;
    forecast: string;
    view: string;
    salesViewDialogTitle: string;
    enterViewTitle: string;
    enterViewTitleHelper: string;
    newView: string;
    saveView: string;
    filter: string;
    views: string;
    availability: string;
    averageAvailability: string;
    unbilableProjectTime: string;
    showUnbilableProjectTime: string;
    lastUpdate: string;
    syncTimes: string;
    lastForecastDate: string;
    showWeeks: string;
    internalProject: string;
    externalProject: string;
    projectWarningTitle: string
    selectedMonths: string;
    actualMonthDataSource: string;
    actualMonthForecastDescription: string;
    actualMonthForecast: string;
    actualMonthTimeRecordingDescription: string;
    actualMonthTimeRecording: string;
    actualMonthCombinedDescription: string;
    actualMonthCombined: string;
    actualMonthDialogDescription: string;
    actualMonthDialogTitle: string;
    showProjectsWithRevenue: string;
    showExternalProjects: string;
    showInternalProjects: string;
    deleteConfirm: string;
    deleteContainsWarning: string;
    sureToDelete: string;
    removeTitle: string;
    showAbsence: string;
    absenceHours: string;
    showProjectsWithoutRevenue: string;
    salesIdea: string;
    noInformationFound: string;
    availabilities: string;
    absence: string;
    bookedHours: string;
    bookedRevenue: string;
    dashboard: string;
    downloadAsCSV: string;
    edit: string;
    employee: string;
    employees: string
    empty: string
    hours: string;
    hoursShort: string;
    leader: string
    open: string
    orgName: string;
    orgUnits: string;
    parentOU: string
    plannedHours: string;
    plannedRevenue: string;
    project: string;
    revenue: string;
    salesDataLink: string;
    search: string;
    searchPlaceholder: string;
    sum: string;
    type: string;
    unknown: string;
}
type ILangData = {
    [lang in ELanguage]: IOrgUnitTexts
}
export const orgunitTexts: ILangData = {
    'th': {
        syncTimes: 'ซิงโครไนซ์เวลา',
        trendBorderTitle: 'การเบี่ยงเบนระหว่างคาดการณ์ / การบันทึกเวลา',
        sensitivityBorder: 'ขีดจำกัดการเบี่ยงเบน',
        showEmployees: 'แสดงพนักงานหรือไม่?',
        forecast: 'พยากรณ์อากาศ',
        view: 'มุมมอง',
        salesViewDialogTitle: 'แก้ไขมุมมองการขาย',
        newView: 'มุมมองใหม่',
        enterViewTitleHelper: 'กรุณาใส่ชื่อสำหรับมุมมองใหม่:',
        enterViewTitle: 'สร้างมุมมอง',
        saveView: 'บันทึกมุมมอง',
        filter: 'ปรับแต่งฟิลเตอร์',
        views: 'มุมมอง',
        availability: 'ความพร้อมใช้งาน',
        averageAvailability: 'ความพร้อมใช้งานเฉลี่ย',
        unbilableProjectTime: 'เวลาในโครงการที่ไม่ได้เรียกเก็บเงิน',
        showUnbilableProjectTime: 'แสดงเวลาในโครงการที่ไม่ได้เรียกเก็บเงิน',
        lastUpdate: 'อัปเดตล่าสุด',
        lastForecastDate: 'การอัปเดตล่าสุด',
        showWeeks: 'แสดงสัปดาห์',
        searchPlaceholder: 'ค้นหาโดยใช้ชื่อพนักงานหรือชื่อโครงการ',
        externalProject: 'ภายนอก',
        internalProject: 'ภายใน',
        projectWarningTitle: 'มีโปรเจกต์ภายนอก [COUNT] โปรเจกต์ที่ไม่ได้ตั้งค่าราคาต่อวัน',
        selectedMonths: 'เดือนที่เลือก',
        actualMonthDataSource: 'แหล่งข้อมูลสำหรับเดือนปัจจุบัน',
        actualMonthForecastDescription: 'เป็นเพียงการประมาณการชั่วโมงทำงานสำหรับเดือนปัจจุบันเท่านั้น',
        actualMonthForecast: 'พยากรณ์',
        actualMonthTimeRecordingDescription: 'เฉพาะเวลาทำงานที่ถูกบันทึกจริงของเดือนปัจจุบัน',
        actualMonthTimeRecording: 'บันทึกเวลา',
        actualMonthCombinedDescription: 'แสดงเวลาทำงานที่บันทึกไว้จนถึงตัวเลขล่าสุด ประกอบด้วยข้อมูลพยากรณ์สำหรับวันถัดไป',
        actualMonthCombined: 'รวม',
        actualMonthDialogDescription: 'ที่นี่คุณสามารถควบคุมวิธีการแสดงข้อมูลสำหรับเดือนปัจจุบัน:',
        actualMonthDialogTitle: 'โหมดสำหรับเดือนปัจจุบัน',
        showProjectsWithRevenue: 'แสดงโปรเจคที่มีรายได้',
        showExternalProjects: 'แสดงโครงการภายนอก',
        showInternalProjects: 'แสดงโครงการภายใน',
        deleteConfirm: 'ฉันยืนยันว่าฉันต้องการลบหน่วยองค์กร',
        deleteContainsWarning: 'หน่วยองค์กรนี้มีสมาชิก [COUNT] คน!',
        sureToDelete: 'คุณแน่ใจหรือว่าต้องการลบหน่วยองค์กร?',
        removeTitle: 'ลบ OU: [TITLE]',
        showAbsence: 'แสดงการขาดงาน',
        showProjectsWithoutRevenue: 'แสดงโครงการที่ไม่มีรายได้',
        absenceHours: 'ชั่วโมงที่ขาดงาน',
        salesIdea: 'ความคิดในการขาย',
        noInformationFound: 'ไม่พบข้อมูล',
        availabilities: 'ความพร้อม',
        oftenUsed: oftenUsedTranslations['th'],
        parentOU: 'หน่วยงานแม่ของหน่วยงานย่อย',
        leader: oftenUsedTranslations['th'].leader,
        empty: oftenUsedTranslations['th'].empty,
        employees: oftenUsedTranslations['th'].employees,
        salesDataLink: 'รายได้',
        open: oftenUsedTranslations['th'].open,
        employee: oftenUsedTranslations['th'].employee,
        hoursShort: oftenUsedTranslations['th'].hoursShort,
        project: oftenUsedTranslations['th'].project,
        dashboard: oftenUsedTranslations['th'].dashboard,
        edit: oftenUsedTranslations['th'].edit,
        downloadAsCSV: oftenUsedTranslations['th'].downloadAsCSV,
        revenue: oftenUsedTranslations['th'].revenue,
        hours: oftenUsedTranslations['th'].hours,
        search: oftenUsedTranslations['th'].search,
        sum: oftenUsedTranslations['th'].sum,
        plannedHours: 'ชั่วโมงที่วางแผน',
        bookedHours: 'ชั่วโมงที่บันทึก',
        plannedRevenue: 'รายได้ที่วางแผน',
        bookedRevenue: 'รายได้ที่บันทึก',
        absence: 'การขาดงาน',
        unknown: 'ไม่ทราบ',
        type: oftenUsedTranslations['th'].type,
        orgUnits: oftenUsedTranslations['th'].orgUnits,
        orgName: oftenUsedTranslations['th'].orgUnit,
    },
    'en': {
        syncTimes: 'Synchronize times',
        trendBorderTitle: 'Deviation Forecast / Time Tracking',
        sensitivityBorder: 'Sensitivity Threshold',
        showEmployees: 'Show employees?',
        forecast: 'Forecast',
        view: 'View',
        salesViewDialogTitle: 'Edit Sales Views',
        newView: 'New View',
        enterViewTitleHelper: 'Please enter a name for the new view:',
        enterViewTitle: 'Create View',
        saveView: 'Save view',
        filter: 'Customize filter',
        views: 'Views',
        availability: 'Availability',
        averageAvailability: 'Average Availability',
        unbilableProjectTime: 'Unbilled project time',
        showUnbilableProjectTime: 'Show unbilled project time',
        lastUpdate: 'Last Update',
        lastForecastDate: 'Last update',
        showWeeks: 'Show weeks',
        searchPlaceholder: 'Search by employee or project name',
        externalProject: 'External',
        internalProject: 'Internal',
        projectWarningTitle: 'There are [COUNT] external projects without a daily rate set.',
        selectedMonths: 'Selected months',
        actualMonthDataSource: 'Data source for current month',
        actualMonthForecastDescription: 'Only the estimated working hours for the current month.',
        actualMonthForecast: 'Forecast',
        actualMonthTimeRecordingDescription: 'Only the actual recorded working hours of the current month.',
        actualMonthTimeRecording: 'Time Recording',
        actualMonthCombinedDescription: 'Shows recorded working hours up to the last entry, supplemented with forecast data for the following days.',
        actualMonthCombined: 'Combined',
        actualMonthDialogDescription: 'Here you can control how data for the current month is displayed:',
        actualMonthDialogTitle: 'Modes for the current month',
        showProjectsWithRevenue: 'Show projects with revenue',
        showExternalProjects: 'Show external projects',
        showInternalProjects: 'Show internal projects',
        deleteConfirm: 'I confirm that I want to delete the organisational unit.',
        deleteContainsWarning: 'This organisational unit contains [COUNT] employees!',
        sureToDelete: 'Are you sure you want to delete the organisational unit?',
        removeTitle: 'Delete OU: [TITLE]',
        showAbsence: 'Show absences',
        showProjectsWithoutRevenue: 'Show projects without revenue',
        absenceHours: 'Absence hours',
        salesIdea: 'Sales idea',
        noInformationFound: 'No information could be found',
        availabilities: 'Availabilities',
        oftenUsed: oftenUsedTranslations['en'],
        parentOU: 'Parent organization unit',
        leader: oftenUsedTranslations['en'].leader,
        empty: oftenUsedTranslations['en'].empty,
        employees: oftenUsedTranslations['en'].employees,
        salesDataLink: 'Revenues',
        open: oftenUsedTranslations['en'].open,
        employee: oftenUsedTranslations['en'].employee,
        hoursShort: oftenUsedTranslations['en'].hoursShort,
        project: oftenUsedTranslations['en'].project,
        dashboard: oftenUsedTranslations['en'].dashboard,
        edit: oftenUsedTranslations['en'].edit,
        downloadAsCSV: oftenUsedTranslations['en'].downloadAsCSV,
        revenue: oftenUsedTranslations['en'].revenue,
        hours: oftenUsedTranslations['en'].hours,
        search: oftenUsedTranslations['en'].search,
        sum: oftenUsedTranslations['en'].sum,
        plannedHours: 'Planned Hours',
        bookedHours: 'Booked Hours',
        plannedRevenue: 'Planned Revenue',
        bookedRevenue: 'Booked Revenue',
        absence: 'Absence',
        unknown: 'Unknown',
        type: oftenUsedTranslations['en'].type,
        orgName: oftenUsedTranslations['en'].orgUnit,
        orgUnits: oftenUsedTranslations['en'].orgUnits,
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        parentOU: 'übergeordnete Organisationseinheit',
        leader: oftenUsedTranslations['de'].leader,
        empty: oftenUsedTranslations['de'].empty,
        employees: oftenUsedTranslations['de'].employees,
        employee: oftenUsedTranslations['de'].employee,
        salesDataLink: 'Umsätze',
        open: oftenUsedTranslations['de'].open,
        hoursShort: oftenUsedTranslations['de'].hoursShort,
        project: oftenUsedTranslations['de'].project,
        dashboard: oftenUsedTranslations['de'].dashboard,
        edit: oftenUsedTranslations['de'].edit,
        downloadAsCSV: oftenUsedTranslations['de'].downloadAsCSV,
        hours: oftenUsedTranslations['de'].hours,
        revenue: oftenUsedTranslations['de'].revenue,
        search: oftenUsedTranslations['de'].search,
        sum: oftenUsedTranslations['de'].sum,
        plannedHours: 'Geplante Stunden',
        bookedHours: 'Geleistete Stunden',
        plannedRevenue: 'Geplanter Umsatz',
        bookedRevenue: 'Geleisteter Umsatz',
        searchPlaceholder: 'Such nach Mitarbeiter oder Projekt-Name',
        absence: 'Abwesenheit',
        unknown: 'Unbekannt',
        type: oftenUsedTranslations['de'].type,
        orgUnits: oftenUsedTranslations['de'].orgUnits,
        orgName: oftenUsedTranslations['de'].orgUnit,
        availabilities: 'Verfügbarkeiten',
        noInformationFound: 'Es konnten keine Informationen gefunden werden',
        salesIdea: 'Vertriebsidee',
        showProjectsWithoutRevenue: 'Projekte ohne Umsatz anzeigen',
        absenceHours: 'Abwesenheitsstunden',
        showAbsence: 'Abwesenheiten anzeigen',
        removeTitle: 'OE Löschen: [TITLE]',
        sureToDelete: 'Sind Sie sicher, dass Sie die Organisationseinheit löschen wollen?',
        deleteContainsWarning: 'Diese Organisationseinheit enthällt [COUNT] Mitarbeiter!',
        deleteConfirm: 'Ich bestätige das Ich die Organisationseinheit löschen möchte.',
        showInternalProjects: 'Interne Projekte anzeigen',
        showExternalProjects: 'Externe Projekte anzeigen',
        showProjectsWithRevenue: 'Projekte mit Umsatz anzeigen',
        actualMonthDialogTitle: 'Modi für den aktuellen Monat',
        actualMonthDialogDescription: 'Hier kannst Du steuern, wie Daten für den aktuellen Monat dargestellt werden:',
        actualMonthCombined: 'Kombiniert',
        actualMonthCombinedDescription: 'Zeigt erfasste Arbeitsstunden bis zum letzten Eintrag, ergänzt mit Forecast-Daten für die folgenden Tage.',
        actualMonthTimeRecording: 'Zeiterfassung',
        actualMonthTimeRecordingDescription: 'Nur die tatsächlich erfassten Arbeitsstunden des aktuellen Monats.',
        actualMonthForecast: 'Forecast',
        actualMonthForecastDescription: 'Ausschließlich die geschätzten Arbeitsstunden für den aktuellen Monat.',
        actualMonthDataSource: 'Datenquelle für aktuellen Monat',
        selectedMonths: 'Ausgewählte Monate',
        projectWarningTitle: 'Es gibt [COUNT] externe Projekte für die kein Tagessatz hinterlegt wurde.',
        internalProject: 'Intern',
        externalProject: 'Extern',
        showWeeks: 'Wochen anzeigen',
        lastForecastDate: 'Letzte aktualisierung',

        syncTimes: 'Zeiten synchronisieren',
        lastUpdate: 'Letzte Aktuallisierung',
        showUnbilableProjectTime: 'Nicht fakturierte Zeiten anzeigen',
        unbilableProjectTime: 'Nicht fakturierte Zeiten',
        averageAvailability: 'Durchschnittliche Verfügbarkeit',
        availability: 'Verfügbarkeit',

        views: 'Ansichten',
        filter: 'Filter anpassen',
        saveView: 'Ansicht speichern',
        enterViewTitle: 'Ansicht erstellen',
        enterViewTitleHelper: 'Bitte gib einen Namen für die neue Ansicht ein:',
        newView: 'Neue Ansicht',
        salesViewDialogTitle: 'Ansichten bearbeiten',
        view: 'Ansicht',
        forecast: 'Forecast',
        showEmployees: 'Mitarbeiter anzeigen?',
        sensitivityBorder: 'Abweichungsgrenze',
        trendBorderTitle: 'Abweichung Forecast / Zeiterfassung',
    },
} 