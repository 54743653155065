import { DirectionsRun, PriorityHigh, Sync } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { ETimeSyncStatus, ETimeSyncStartType, ETimeSyncOption, ICompanyTimeSyncStatis } from '@sharedInterfaces/ICompanySettings';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import getCompanySettings from '@src/APIs/graphQl/Company/getCompanySettings';
import startClockodoSync from '@src/APIs/graphQl/Company/startClockodoSync';
import { AppState } from '@store/store';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

export default function SyncClockodoButton()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const permissions = useSelector((state: AppState) => state.permissions);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const companyId = useSelector((state: AppState) => state.company.companyId);

    const [clockodoData, setClockodoData] = useState<ICompanyTimeSyncStatis | null>(null);

    const setStatus = (status: ETimeSyncStatus) => clockodoData && setClockodoData({ ...clockodoData, status: status });
    const loadData = () => getCompanySettings(companyId).then(company =>
    {
        if (company.companySettings.timeSync !== ETimeSyncOption.CLOCKODO) return;
        setClockodoData(company.companySettings.clockodoStatis);
    });
    useEffect(() =>
    {
        if (!permissions.admin) return;
        loadData();
        const timeout = setInterval(() =>
        {
            if (clockodoData?.status !== ETimeSyncStatus.SYNCING)
            {
                clearInterval(timeout);
                return;
            }

            loadData();
        }, 30000);
        return () => clearInterval(timeout);
    },
        [clockodoData?.status]);


    if (!permissions.admin) return <div></div>
    const onClick = function (): void
    {
        startClockodoSync(ETimeSyncStartType.NORMAL)
            .then(() => setStatus(ETimeSyncStatus.SYNCING))
            .catch(() => setStatus(ETimeSyncStatus.ERROR))
            .finally(() =>
            {
                setTimeout(loadData, 2000);
            });
    };
    const icon = clockodoData?.status === ETimeSyncStatus.SYNCING
        ? <DirectionsRun /> :
        clockodoData?.status === ETimeSyncStatus.ERROR
            ? <PriorityHigh />
            : <Sync />;

    return <div>
        <MenuItem
            onClick={onClick}
            disabled={!clockodoData || clockodoData.status === ETimeSyncStatus.SYNCING || clockodoData.status === ETimeSyncStatus.ERROR}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText>
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                    {langStrings.syncClockodo}
                    <Typography variant='caption'>
                        {langStrings.lastUpdate}:
                    </Typography>
                    <Typography variant='caption'>
                        {
                            !clockodoData?.lastUpdateEmployeeTimesThisYear
                                ? '-'
                                : new Date(clockodoData.lastUpdateEmployeeTimesThisYear).toLocaleString()
                        }
                    </Typography>
                </div>
            </ListItemText>
        </MenuItem>
    </div>
}