
import React, { useState } from 'react';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { useSelector } from 'react-redux';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '@src/Components/formsControls/inputs/TextInput/TextInput';
import ErrorBox from '@src/Components/ErrorBox/ErrorBox';
import Button from '@sharedReact/General/Button/Button';
import { Done } from '@mui/icons-material';
import { ETimeSyncStatus, ICompanyTimeSyncStatis } from '@sharedInterfaces/ICompanySettings';
import setZepSync from '@src/APIs/graphQl/Company/setZepSync';
import Company from '@src/Objects/Company';

import { StatusTable } from './ClockodoAccordion';

interface ZepAccordionProps
{
    active: boolean;
    statis: ICompanyTimeSyncStatis
    zepInstance: string;
    setCompany: (company: Company) => void;
    reload: () => void;
}
export function ZepAccordion({ active, statis, zepInstance, setCompany, reload }: ZepAccordionProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [changed, setChanged] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(active ? 'zep' : false);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [token, setToken] = useState<string>("");
    const [instance, setInstance] = useState<string>(zepInstance);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) =>
    {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Accordion
            expanded={active || expanded === 'zep'}
            disabled={saving}
            onChange={handleChange('zep')}
        >
            <AccordionSummary>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <div
                        style={{
                            backgroundImage: `url(/icons/zep-50.png)`,
                            width: '30px',
                            height: '30px',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Typography fontWeight={active ? 'bold' : 'normal'}>{langStrings.zep}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <RowElement alignTitle='left' title={langStrings.zepInstance}>
                    <TextInput
                        value={instance}
                        size='small'
                        onChange={(text) =>
                        {
                            setChanged(true);
                            setInstance(text);
                        }} />
                </RowElement>
                <RowElement alignTitle='left' title={langStrings.zepToken}>
                    <TextInput
                        value={token}
                        password={true}
                        size='small'
                        onChange={(text) =>
                        {
                            setChanged(true);
                            setToken(text);
                        }} />
                </RowElement>
                {saveError && <ErrorBox close={setSaveError.bind(null, null)}>{saveError}</ErrorBox>}

                {changed &&
                    <Button
                        disabled={saving || token === '' || token.length !== 64}
                        size={'normal'}
                        text={langStrings.save}
                        icon={<Done />}
                        color='primary'
                        onClick={function (): void
                        {
                            setSaving(true);
                            setZepSync({
                                instance,
                                token,
                            })
                                .then((companyData) =>
                                {
                                    const error = companyData.clockodoStats.status === ETimeSyncStatus.ERROR; //TODO: CHANGE
                                    console.log('companyData', companyData.clockodoStats.status);

                                    setCompany(companyData);
                                    if (error)
                                    {
                                        setSaveError(langStrings.errorLoginData);
                                    } else
                                    {
                                        setSaveError(null);
                                    }
                                }).then(() => setChanged(false))
                                .catch(err => setSaveError(err.toString()))
                                .finally(() =>
                                {
                                    setSaving(false);
                                    setTimeout(reload, 2000);
                                });
                        }}
                    />
                }
                <StatusTable
                    statis={statis}
                    syncIsActive={active}
                    reload={reload}
                />
            </AccordionDetails>
        </Accordion>
    );
}