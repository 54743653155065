import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { EEntityType, ELinks, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import './OrgUnitPage.css';
import { IOU } from '@sharedInterfaces/ICompanySettings';
import { IOrgUnitDetailDTO, IOrgUnitSalesDataDTO, IOUAvailabilityDataEmployeesDTO } from '@sharedInterfaces/IOrgUnit';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { AppState } from '@store/store';
import getOrgUnit from '@src/APIs/graphQl/OrgUnit/getOrgUnit';
import getOrgUnitSalesData from '@src/APIs/graphQl/OrgUnit/getOrgUnitSalesData';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import getOrgUnitAvailabilityData from '@src/APIs/graphQl/OrgUnit/getOrgUnitAvailabilityData';
import SkillsCard from '@src/App/NewLayout/Cards/Skills/SkillsCard/SkillsCard';
import SkillCategoriesCard from '@src/App/NewLayout/Cards/SkillCategories/SkillCategoriesCard/SkillCategoriesCard';
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import SyncTimeButton from '@src/Components/Buttons/SyncTimeButton/SyncTimeButton';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';

import { AvailabilitiesContent } from './AvailabilitiesContent';
import { SalesContent } from './SalesContent';

interface OrgUnitPageProps
{
    entity?: IOrgUnitDetailDTO;
    inDialog?: boolean;
}

/**
 * Represents the OrgUnitPage component.
 * 
 * @param {OrgUnitPageProps} props - The props for the OrgUnitPage component.
 * @returns {JSX.Element} The JSX element representing the OrgUnitPage component.
 */
function OrgUnitPage(props: OrgUnitPageProps)
{
    const { id, tab } = useParams();
    const entityId = props.entity ? props.entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const OUs = useSelector((state: AppState) => state.OUs);
    const permissions = useSelector((state: AppState) => state.permissions);
    const entityState = useSelector((state: AppState) => state.orgUnits.find(a => a.id == entityId))


    const [salesData, setSalesData] = useState<IOrgUnitSalesDataDTO | null>(null);
    const [availabilityData, setAvailabilityData] = useState<IOUAvailabilityDataEmployeesDTO | null>(null);
    const [loadingSalesData, setLoadingSalesData] = useState<boolean>(false);
    const [loadingAvailabilityData, setLoadingAvailabilityData] = useState<boolean>(false);
    const [parentOU, setParentOU] = useState({} as IOU);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);


    useEffect(() =>
    {
        if (entityId === -1) return;
        setSalesData(null);
        setAvailabilityData(null);
        setLoadingAvailabilityData(false);
        setLoadingSalesData(false);
    }, [entityId])

    useEffect(() =>
    {
        if (props.inDialog) return;
        if (entityId === -1) return;

        getOrgUnit(entityId)
            .then(() =>
            {
                const loadedOU = OUs.find(cat => cat.id === entityId)
                if (loadedOU && loadedOU.parentOU)
                {
                    const parentOU = OUs.find(cat => cat.id === loadedOU.parentOU)
                    if (parentOU)
                        setParentOU(parentOU);
                    else
                        setParentOU({} as IOU);
                } else
                    setParentOU({} as IOU);
            })
            .catch((ex) =>
            {
                setLoadingError(ex);
            });
    }, [OUs, entityId, props.inDialog]);

    const loadSalesData = () =>
    {
        if (salesData) return;
        if (loadingSalesData) return;
        const salesDataT = localStorage.getItem(`OrgSalesData-${entityId}`);
        if (salesDataT)
        {
            setSalesData(JSON.parse(salesDataT));
        }
        setLoadingSalesData(true);
        getOrgUnitSalesData(entityId, (details) =>
        {
            setSalesData(details);
            //Save in Local Storage with "OrgSalesData-OrgId" als Key
            localStorage.setItem(`OrgSalesData-${entityId}`, JSON.stringify(details));
        }).catch((ex) =>
        {
            setLoadingError(ex);
        });
    }
    const loadSalesAvailability = () =>
    {
        if (availabilityData) return;
        if (loadingAvailabilityData) return;
        const availabilityDataT = localStorage.getItem(`OrgAvailabilityData-${entityId}`);
        if (availabilityDataT)
        {
            const tempData = JSON.parse(availabilityDataT) as IOUAvailabilityDataEmployeesDTO;
            tempData.employees.forEach(data => data.lastForecastDate = new Date(data.lastForecastDate));
            setAvailabilityData(tempData);
        }
        setLoadingAvailabilityData(true);
        getOrgUnitAvailabilityData(entityId, (details) =>
        {
            setAvailabilityData(details);
            //Save in Local Storage with "OrgAvailabilityData-OrgId" als Key
            localStorage.setItem(`OrgAvailabilityData-${entityId}`, JSON.stringify(details));
        }).catch((ex) =>
        {
            setLoadingError(ex);
        });
    }
    useEffect(() =>
    {
        if (props.inDialog) return;
        if (entityId === -1) return;
        if (OUs.length === 0) return;
        if (tab === 'sales')
        {
            loadSalesData();
        }
        else if (tab === 'availabilities')
        {
            loadSalesAvailability();
        }
    }, [OUs, entityId, props.inDialog, tab, loadingAvailabilityData, loadingSalesData]);

    const dashboardContent = <>
        {entityState ?
            <>
                <EmployeesCard employees={entityState.employees.map(e => ({ id: e.id, title: generateFullName(e.firstname, e.lastname) }))}
                    inOverlay={props.inDialog}
                />
                <SkillCategoriesCard skillCategories={entityState.skillCategories} />
                <SkillsCard skills={entityState.skills} />
            </> :
            <></>}
    </>;


    let content = dashboardContent;
    if (tab === 'sales' && checkPermissions('OrgUnits', 'RetrieveSalesData', permissions))
    {
        content = <SalesContent salesData={salesData} refresh={() =>
        {
            setSalesData(null)
            setLoadingSalesData(false);
            setTimeout(() =>
            {
                loadSalesData();
            }, 500);
        }} />;
    } else if (tab === 'availabilities' && entityState)
    {
        content = <AvailabilitiesContent allowSales={checkPermissions('OrgUnits', 'RetrieveSalesData', permissions)} availabilityData={availabilityData} />;
    }
    return (
        <EntityPage
            inDialog={props.inDialog}
            entity={entityState ? entityState : undefined}
            admin={permissions.admin}
            permissions={permissions.entities.SkillCategories}
            loadingError={loadingError}
            menuItems={permissions.admin ? [<SyncTimeButton key={'timeSync'} />] : undefined}
            tabs={props.inDialog ? undefined : [
                {
                    title: langStrings.dashboard,
                    link: `/${ELinks.PEOPLE_ORGUNITS}/${id}/dashboard`
                },
                {
                    title: langStrings.availabilities,
                    link: `/${ELinks.PEOPLE_ORGUNITS}/${id}/availabilities`
                },
                ...(checkPermissions('OrgUnits', 'RetrieveSalesData', permissions) ?
                    [{
                        title: langStrings.salesDataLink,
                        link: `/${ELinks.PEOPLE_ORGUNITS}/${id}/sales`
                    }]
                    : []
                )
            ]}
            informations={!entityState ? [] :
                [
                    entityState.leader.id !== -1 ?
                        <EntityPageInformation title={langStrings.leader} >
                            <NavLink to={entityTypeToLink(entityState.leader.id, EEntityType.EMPLOYEE)}>{generateFullName(entityState.leader.firstname, entityState.leader.lastname)}</NavLink>
                        </EntityPageInformation>
                        : undefined,
                    parentOU.id ?
                        <EntityPageInformation title={langStrings.parentOU} >
                            <NavLink to={`/${ELinks.PEOPLE_ORGUNITS}/${parentOU.id}`}>{parentOU.title}</NavLink>
                        </EntityPageInformation>
                        : undefined,
                ]
            }
        >
            {content}
        </EntityPage >
    );
}

export default OrgUnitPage;

